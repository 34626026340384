


import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import striptags from "striptags";

import { HollowDotsSpinner } from "epic-spinners";
import stores from "@/store/store";
import { VueEditor } from "vue2-editor";
import { log } from "util";
const store: any = stores;
@Component({
  components: { HollowDotsSpinner, VueEditor }
})
export default class ReplyFromWemloSupport extends Vue {
  public customToolBar = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }]
  ];
  public replyMessage = "";
  public mailSent = false;
  public showReplyPage = null;
  public messageData: any = {};
  public message = "";
  public hideErrorMsg = false;
  public weHelpId = null;
  public async saveWeHelpTeamResponse() {
    try {
      let formValid = await this.$validator.validate("message");
      if (!formValid) {
        return;
      }
      this.$store.state.wemloLoader = true;
      let body = {
        replyMessage: this.replyMessage,
        id: this.weHelpId
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/saveWeHelpTeamResponse",
        body
      );
      this.$store.state.wemloLoader = false;
      if (response.data.message == "mailSent") {
        this.mailSent = true;
        this.$snotify.success("Email sent successfully");
      } else if (response.data.message == "mailNotSent") {
        this.$snotify.error("email is not sent");
      }
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public removeTags(message) {
    let stripedMessage = striptags(message, [], " ");
    let resMessage = stripedMessage.replace(/\s\s+/g, " ");
    return resMessage;
  }

  public async getWehelpDetails() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getWehelpDetails",
        {
          token: this.$route.query.token
        }
      );
      if (response.data.token == 500) {
        this.weHelpId = response.data.weHelpId;
        this.messageData = response.data.data;
        this.message = this.messageData.message;
      } else if (response.data.token == 200) {
        this.$router.push("/");
        this.$snotify.error("Token has expired");
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async openModelForMessage(){
    this.$modal.show('showFullResponse')

    let scenarioReply = document.getElementById('reply-modal-fixed');
    scenarioReply.classList.add('reply-modal-fixed')
  }
  public hideFullMessageModal(){
    let scenarioReply = document.getElementById('reply-modal-fixed');
    scenarioReply.classList.remove('reply-modal-fixed')
   this.$modal.hide('showFullResponse')

  }
  async mounted() {
    this.getWehelpDetails();
  }
}
