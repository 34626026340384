


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";

import { BASE_API_URL } from "@/config";
import { VueEditor } from "vue2-editor";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({ components: { VueEditor } })
export default class TitleOrderConfirmation extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public files = [];
  public titleObj = {
    isTitleOrdered: true,
    titleCompanyNo: null,
    titleCompanyMessage: null,
  };
  public isTitleOrderCompleted = false;
  public async verifyTokenOfTitleCompany() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyTokenOfTitleCompany",
        {
          token: this.$route.query.id,
          loanTxnId: this.$route.query.loanTxnId
        }
      );
      if(response.data.msg != "success"){
        this.isTitleOrderCompleted = true;
        this.$snotify.error("Your link has expired");
      }
    } catch (error) {}
  }

  public async confirmTitleOrderFromEmail() {
    this.$store.state.wemloLoader = true;
    try {
      let formData = new FormData();
      formData.append("data", JSON.stringify({
        loanTxnId: this.$route.query.loanTxnId,
        token: this.$route.query.id,
        titleCompanyNo: this.titleObj.titleCompanyNo,
        titleCompanyMessage: this.titleObj.titleCompanyMessage,
        isTitleOrdered: this.titleObj.isTitleOrdered,
      }));
      this.files.forEach(file => {
        formData.append("file", file);
      });
      let response = await Axios.post(
        BASE_API_URL + "titleOrder/confirmTitleOrderFromEmail",
        formData
      );
      if (response.data.msg == "success") {
        this.$snotify.success("Title order task confirmed successfully");
         this.$router.push("/");
      } else {
        this.isTitleOrderCompleted = true;
        this.$snotify.error("Your link has expired");
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
    
  }

  public fileUploads(event) {
    try {
      Object.values(event.target.files).forEach(e => {
        this.files.push(e);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {

    this.verifyTokenOfTitleCompany();
  }
}
